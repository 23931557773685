import React, { Component } from 'react';
import { Container, Button } from 'reactstrap';
import { withRouter } from 'react-router';
import Spinner from './components/Spinner';
import Header from './components/Header';
import EventHeader from './components/EventHeader';

import decoratedFetch from './helpers/fetch';
import 'moment/locale/nl';
import './css/LandingPage.css';

// TODO: refactor to use hooks
// https://stackoverflow.com/a/54159564
const utilizeFocus = () => {
  const ref = React.createRef();
  const setFocus = () => {
    // eslint-disable-next-line no-unused-expressions
    ref.current && ref.current.focus();
  };

  return { setFocus, ref };
};

class RedirectPage extends Component {
  constructor(props) {
    super(props);
    this.state = {
      unlockCode: '',
      data: null,
      errors: null,
      redirectId: null,
    };
    this.inputFocus = utilizeFocus();
    this.handleChange = this.handleChange.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
  }

  componentDidMount() {
    const redirectId = this.discoverRedirectId();
    if (redirectId) {
      this.setState({ redirectId }, this.doFetch);
    } else {
      this.setErrorState();
    }
  }

  setErrorState(errors) {
    this.setState({
      data: { name: 'Ongeldige URL', errored: true },
      errors,
    });
  }

  setCustomCss() {
    const { css } = this.state;
    const element = document.getElementById('dynamic_style');
    if (typeof element === 'undefined' || element == null) {
      const style = document.createElement('style');
      style.setAttribute('id', 'dynamic_style');
      document.head.appendChild(style);
      style.appendChild(document.createTextNode(css));
    }
  }

  doTransition() {
    const { data } = this.state;
    const url = `/${data.ulid}`;
    const { history } = this.props;
    this.inputFocus.setFocus();
    if (data.ulid) {
      history.push(url);
    }
  }

  doFetch() {
    const { redirectId } = this.state;
    const url = `/redirects/${redirectId}.json`;
    const passthroughDefaults = { organisation: { name: '' }, css: {} };
    decoratedFetch(url)
      .then(response => response.json())
      .then(response => {
        const { data } = response;
        const { organisation, css } =
          data.encoder_passthrough || passthroughDefaults;

        this.setState(
          {
            data,
            organisation,
            css,
          },
          this.setCustomCss,
        );
      })
      .catch(error => this.setErrorState(error));
  }

  doCheck() {
    const { redirectId, unlockCode } = this.state;
    const url = `/redirects/${redirectId}.json`;
    decoratedFetch(url, {
      method: 'PUT',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({
        data: {
          id: redirectId,
          type: 'redirects',
          attributes: {
            unlock_code: unlockCode,
          },
        },
      }),
    })
      .then(response => response.json())
      .then(response => {
        if (response.errors) {
          this.setState(
            {
              errors: response.errors,
              unlockCode: '',
            },
            this.doTransition,
          );
        } else {
          this.setState(
            {
              data: response.data.attributes,
            },
            this.doTransition,
          );
        }
      })
      .catch(errors =>
        this.setState(
          {
            errors,
          },
          this.doTransition,
        ),
      );
  }

  // eslint-disable-next-line class-methods-use-this
  discoverRedirectId() {
    return window.location.pathname.split('/')[2]; // /redirect/:redirect_id
  }

  handleChange(event) {
    this.setState({ unlockCode: event.target.value.toUpperCase() });
  }

  handleSubmit(event) {
    this.doCheck();
    event.preventDefault();
  }

  inputValid() {
    const { unlockCode } = this.state;
    return unlockCode.length !== 6;
  }

  render() {
    const { data, errors, unlockCode, organisation } = this.state;
    let event = { state: 'inactive' };
    if (data) {
      event = { ...event, ...data };

      return (
        <>
          <Header organisation={organisation} />
          <EventHeader event={event} />
          {!event.errored && (
            <Container className="mt-4">
              <section className="container text-center">
                <h5>Voer hier uw verkregen code in:</h5>

                <form
                  className="text-center mb-4 "
                  onSubmit={this.handleSubmit}
                >
                  <input
                    type="text"
                    value={unlockCode}
                    onChange={this.handleChange}
                    className="col-8 col-sm-4 mb-4 form-control"
                    id="1"
                    maxLength="6"
                    style={{
                      textAlign: 'center',
                      letterSpacing: '3px',
                      width: 'auto',
                      display: 'inline-block',
                    }}
                    autoFocus
                    ref={this.inputFocus.ref}
                    // pattern="[0-9AC-HJKMNP-TV-Z]{6}"
                  />
                  <br />
                  <Button color="primary" disabled={this.inputValid()}>
                    Verzend
                  </Button>
                </form>
                {errors &&
                  errors[0] &&
                  errors[0].meta.attribute === 'unlock_code' && (
                    <p className="text-danger">
                      De ingevoerde code is niet correct
                      <br />
                      Probeer het nog een keer
                    </p>
                  )}
              </section>
            </Container>
          )}
        </>
      );
    }
    return (
      <>
        <Header />
        <Container className="text-center">
          <Spinner />
        </Container>
      </>
    );
  }
}

export default withRouter(RedirectPage);
