import React from 'react';

import { BrowserRouter as Router, Switch, Route } from 'react-router-dom';
import { Container, Col } from 'reactstrap';
import { useCookies } from 'react-cookie';
import { v4 as uuidv4 } from 'uuid';
import ReactGA from 'react-ga';
import LandingPage from './LandingPage';
import RedirectPage from './RedirectPage';
import Header from './components/Header';
import ErrorMessage from './components/ErrorMessage';

ReactGA.initialize(process.env.REACT_APP_GOOGLE_ANALYTICS);
ReactGA.pageview(window.location.pathname + window.location.search);

export default function App() {
  const [cookies, setCookie] = useCookies(['playerId']);
  let { playerId } = cookies;

  if (!playerId) {
    playerId = `memoriamtv-${uuidv4()}`;
    setCookie('playerId', playerId, { path: '/' });
  }

  return (
    <Router>
      <div>
        {/* A <Switch> looks through its children <Route>s and
            renders the first one that matches the current URL. */}
        <Switch>
          <Route path="/redirect/*">
            <RedirectPage playerId={playerId} />
          </Route>
          <Route path="/:eventId">
            <LandingPage playerId={playerId} />
          </Route>
          <Route path="/">
            <Header />
            <Container>
              <br />
              <Col>
                <h2>Er is geen uitzending op deze pagina.</h2>
                <ErrorMessage />
              </Col>
            </Container>
          </Route>
        </Switch>
      </div>
    </Router>
  );
}
