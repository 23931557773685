import React from 'react';
import PropTypes from 'prop-types';
import { Container } from 'reactstrap';
import logoRechts from '../images/logo.png';
import '../css/Header.css'

function Header({ organisation }) {
  const logo = organisation && organisation.logo
  return (
    <Container fluid className="header--wrapper">
      <Container>
        <header>
          {logo && (
            <img id="logo-left" src={logo} className="logo" alt="Logo" />
          )}
          <img
            id="logo-right"
            src={logoRechts}
            className={`logo pull-right ${logo ? 'd-none d-sm-block' : ''}`}
            alt="Logo Memoriam.tv"
          />
        </header>
      </Container>
    </Container>
  );
}

Header.propTypes = {
  organisation: PropTypes.shape({
    logo: PropTypes.string,
  }),
};
Header.defaultProps = {
  organisation: {
    logo: null,
  },
};
export default Header;
