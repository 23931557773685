// Taken from https://dev.to/finallynero/using-websockets-in-react-4fkp
import React, { Component } from 'react';

export default class LiveCounter extends Component {
  constructor(props) {
    super(props);
    this.state = {
      ws: null,
      counter: 0
    };
  }

  // single websocket instance for the own application and constantly trying to reconnect.
  componentDidMount() {
    if (this.props.event) {
      this.connect();
    }
  }
  componentWillUnmount() {
    if (this.state.ws) {
      this.state.ws.close();
    }
  }
  componentDidUpdate(prevProps) {
    if (prevProps.event.state !== this.props.event.state) {
      if (this.state.ws) {
        this.state.ws.send(this.props.event.state);
      }
    }
  }

  // props change eventId

  timeout = 250; // Initial timeout duration as a class variable

  /**
   * @function connect
   * This function establishes the connect with the websocket and also ensures constant reconnection if connection closes
   */
  connect = () => {
    const { playerId, event } = this.props;
    var ws = new WebSocket(process.env.REACT_APP_MEMORIAM_TV_LIVE_COUNTER_HOST + 'counter/' + event.id + '?playerId=' + playerId);
    let that = this; // cache the this
    var connectInterval;

    // websocket onopen event listener
    ws.onopen = () => {
      // console.log('connected websocket main component');
      ws.send(event.state);
      this.setState({ ws });
      that.timeout = 250; // reset timer to 250 on open of websocket connection
      clearTimeout(connectInterval); // clear Interval on on open of websocket connection
    };

    // websocket onclose event listener
    ws.onclose = e => {
      // console.log(
      //   `Socket is closed. Reconnect will be attempted in ${Math.min(
      //     10000 / 1000,
      //     (that.timeout + that.timeout) / 1000,
      //   )} second.`,
      //   e.reason,
      // );

      that.timeout = that.timeout + that.timeout; //increment retry interval
      connectInterval = setTimeout(this.check, Math.min(10000, that.timeout)); //call check function after timeout
    };

    // websocket onerror event listener
    ws.onerror = err => {
      console.error(
        'Socket encountered error: ',
        err.message,
        'Closing socket',
      );

      ws.close();
    };

    ws.onmessage = function(message) {
      that.setState({ counter: message.data });
    };
  };

  /**
   * utilited by the @function connect to check if the connection is close, if so attempts to reconnect
   */
  check = () => {
    const { ws } = this.state;
    if (!ws || ws.readyState === WebSocket.CLOSED) this.connect(); //check if websocket instance is closed, if so call `connect` function.
  };

  render() {
    return <div id="LiveCounter"/>;
    // Er zijn op dit moment {this.state.counter} kijkers</div>;
  }
}
