/* eslint-disable react/prop-types */
/* eslint-disable camelcase */
import React, { Component } from 'react';
import { Container, Col } from 'reactstrap';
import moment from 'moment';
import LiveCounter from './components/LiveCounter';
import Downloads from './components/Downloads';
import VideoPlayer from './components/VideoPlayer';
import Spinner from './components/Spinner';
import Header from './components/Header';
import EventHeader from './components/EventHeader';
import ErrorMessage from './components/ErrorMessage';
import decoratedFetch from './helpers/fetch';
import 'moment/locale/nl';
import './css/LandingPage.css';

// TODO: Warning: Can't perform a React state update on an unmounted component.
// This is a no-op, but it indicates a memory leak in your application. To fix,
// cancel all subscriptions and asynchronous tasks in componentWillUnmount.

export default class LandingPage extends Component {
  constructor(props) {
    super(props);
    this.state = {
      data: null,
      error: null,
      eventId: null,
      searchQuery: null,
      requestPending: false,
      embed: false,
    };
  }

  componentDidMount() {
    const eventId = this.discoverEventId();
    const searchQuery = this.discoverSearchQuery();
    const embed = this.discoverEmbed();
    if (eventId) {
      this.setState({ eventId, searchQuery, embed }, this.startRequestTimer);
    } else {
      this.setState({ data: { name: 'Ongeldige URL' } });
    }
  }

  componentWillUnmount() {
    this.stopRequestTimer();
  }

  setErrorState(error) {
    this.setState(
      {
        data: { name: 'Ongeldige URL' },
        error,
      },
      this.stopRequestTimer,
    );
  }

  setCustomCss() {
    const { css } = this.state;
    const element = document.getElementById('dynamic_style');
    if (typeof element === 'undefined' || element == null) {
      const style = document.createElement('style');
      style.setAttribute('id', 'dynamic_style');
      document.head.appendChild(style);
      style.appendChild(document.createTextNode(css));
    }
  }

  startRequestTimer() {
    this.isItLive(); // initial call
    this.timer = setInterval(() => this.isItLive(), 5000);
  }

  stopRequestTimer() {
    clearInterval(this.timer);
    this.timer = null;
  }

  checkIfFinalState() {
    const { data } = this.state;
    const currentTime = new Date(new Date().toUTCString());
    const stopAt = new Date(new Date(data.stop_at).toUTCString());

    if (
      stopAt < currentTime &&
      data.state === 'on_demand' &&
      data.downloads &&
      data.downloads.status === 'ready'
    ) {
      this.stopRequestTimer();
    }
  }

  // https://www.crockford.com/base32.html
  // eslint-disable-next-line class-methods-use-this
  discoverEventId() {
    let eventId = window.location.pathname.split('/')[1]; // Strip first slash
    eventId = eventId.toUpperCase();
    eventId = eventId.replace(/-/g, '');     // strip -
    eventId = eventId.replace(/[IL]/g, '1'); // convert i I l L
    eventId = eventId.replace(/O/g, '0');    // convert O o
    return eventId;
  }

  // eslint-disable-next-line class-methods-use-this
  discoverEmbed() {
    return window.location.pathname.split('/')[2] === 'embed'; // Strip first slash
  }

  // eslint-disable-next-line class-methods-use-this
  discoverSearchQuery() {
    return window.location.search;
  }

  isItLive() {
    const { requestPending } = this.state;
    if (!requestPending) {
      this.setState({ requestPending: true }, this.doFetch);
    }
  }

  doFetch() {
    const { eventId, searchQuery } = this.state;
    const url = `/broadcasts/${eventId}.json${searchQuery}`;
    const passthroughDefaults = { organisation: { name: '' }, css: {} };
    decoratedFetch(url)
      .then(response => response.json())
      .then(response => {
        const { data } = response;
        const { organisation, css } =
          data.encoder_passthrough || passthroughDefaults;
        this.setState(
          {
            data,
            organisation,
            css,
            requestPending: false,
          },
          () => {
            this.checkIfFinalState();
            this.setCustomCss();
          },
        );
      })
      .catch(error => this.setErrorState(error));
  }

  render() {
    const { playerId } = this.props;
    const { data: event, error, embed, organisation, eventId } = this.state;
    let splitView = false;
    if (event) {
      const currentTime = new Date(new Date().toUTCString());
      const stopAt = new Date(new Date(event.stop_at).toUTCString());
      if (event.downloads && stopAt < currentTime) {
        splitView = true;
      }
      if (embed) {
        return (
          <div className="is-embedded">
            <LiveCounter event={event} playerId={playerId} />
            <Player
              event={event}
              eventId={eventId}
              error={error}
              playerId={playerId}
              organisation={organisation}
              theme="vjs-theme-default"
              embed
            />
          </div>
        );
      }
      return (
        <>
          <LiveCounter event={event} playerId={playerId} />
          <Header organisation={organisation} />
          <Container className="LandingPage">
            <section>
              <article className="row">
                <EventHeader event={event} />
                <Col sm={splitView ? 8 : 12}>
                  <Player
                    event={event}
                    eventId={eventId}
                    error={error}
                    playerId={playerId}
                    organisation={organisation}
                  />
                </Col>
                {event.downloads && (
                  <Col sm={4}>
                    <Downloads
                      downloads={event.downloads}
                      expires_at={event.expires_at}
                    />
                  </Col>
                )}
              </article>
            </section>
            {/* <aside>
              <h4>Over Memoriam.tv</h4>
              <p className="text-center">
                Donec eu libero sit amet quam egestas semper. Aenean ultricies
                mi vitae est. Mauris placerat eleifend leo.
              </p>
            </aside> */}
          </Container>
        </>
      );
    }
    return (
      <>
        <Header />
        <Container className="LandingPage">
          <Spinner />
        </Container>
      </>
    );
  }
}

function Player(props) {
  const { event, error, playerId, organisation, eventId, embed, theme } = props;
  const {
    state,
    playback_token,
    id: videoId,
    name: videoTitle,
    clipped_start: clippedStart,
    clipped_stop: clippedStop,
  } = event;

  const currentTime = new Date(new Date().toUTCString());
  const startAt = new Date(new Date(event.start_at).toUTCString());
  const stopAt = new Date(new Date(event.stop_at).toUTCString());
  const expiresAt = moment(event.expires_at).format('LL');

  let { playback_url } = event;
  if (playback_token && playback_url) {
    playback_url = [playback_url, '?token=', event.playback_token].join('');
  }
  const sources = [
    {
      src: playback_url,
      type: 'application/x-mpegURL',
    },
  ];

  return (
    <>
      {error && <ErrorMessage eventId={eventId} />}
      {(state === 'live' || (state === 'on_demand' && stopAt < currentTime)) &&
        playback_url && (
          <VideoPlayer
            playerId={playerId}
            eventId={eventId}
            sources={sources}
            poster={event.thumbnail_url}
            videoTitle={videoTitle}
            videoId={videoId}
            organisation={organisation}
            streamType={state}
            clippedStart={clippedStart}
            clippedStop={clippedStop}
            theme={theme}
          />
        )}
      {state !== 'live' && currentTime < stopAt && (
        <>
          {embed && <EventHeader event={event} />}
          <Spinner />
          {currentTime < startAt && (
            <h3>De live uitzending is nog niet begonnen.</h3>
          )}
          {startAt < currentTime && currentTime < stopAt && (
            <h3>De live uitzending is onderbroken.</h3>
          )}
          <p className="text-center">een moment geduld A.U.B.</p>
        </>
      )}
      {!embed && state === 'on_demand' && stopAt < currentTime && (
        <>
          &nbsp;
          <h3>De live uitzending is afgelopen</h3>
          <p>
            U kunt nu de gehele uitzending on demand tot {expiresAt}{' '}
            terugkijken.
          </p>
        </>
      )}
      {state === 'deleted' && (
        <>
          <h3>De live uitzending is afgelopen</h3>
          <p>Tevens is ook de opname al verwijderd.</p>
        </>
      )}
    </>
  );
}
