/* eslint-disable react/prop-types */
import React from 'react';
import videojs from 'video.js';
import ducthLang from 'video.js/dist/lang/nl.json';
import '../css/VideoPlayer.css';
import '@videojs/themes/dist/fantasy/index.css';
import 'videojs-mux';
import 'videojs-contrib-quality-levels';
import 'videojs-hls-quality-selector';
import 'videojs-offset';

class VideoPlayer extends React.Component {
  constructor(props) {
    super(props);
    const { playerId } = props;
    const playerInitTime = Date.now();
    const organisationName = props.organisation.name || '';
    const videoTitle = props.videoTitle || '';
    const videoId = props.videoId || '';
    const streamType = props.streamType || '';

    videojs.addLanguage('nl', ducthLang);

    this.playerSettings = {
      // eslint-disable-next-line react/prop-types
      controls: true,
      liveui: true,
      playsinline: true,
      preload: 'none',
      language: 'nl',
      html5: {
        hls: {
          overrideNative: !videojs.browser.IS_SAFARI,
        },
        nativeVideoTracks: videojs.browser.IS_SAFARI,
        nativeAudioTracks: videojs.browser.IS_SAFARI,
      },
      plugins: {
        mux: {
          debug: false,
          respectDoNotTrack: false, // Track browsers even where Do Not Track is enabled
          data: {
            env_key: process.env.REACT_APP_MUX_DATA_KEY, // required

            // Site Metadata
            viewer_user_id: playerId, // ex: '12345'
            experiment_name: '', // ex: 'player_test_A'
            sub_property_id: '', // ex: 'cus-1'

            // Player Metadata
            player_name: process.env.REACT_APP_CI_REPO_NAME, // ex: 'My Main Player'
            player_version: `${process.env.REACT_APP_CI_BRANCH}#${process.env.REACT_APP_COMMIT_ID}`, // ex: '1.0.0'
            player_init_time: playerInitTime, // ex: 1451606400000

            // Video Metadata (cleared with 'videochange' event)
            video_id: videoId, // ex: 'abcd123'
            video_title: videoTitle, // ex: 'My Great Video'
            video_series: organisationName, // ex: 'Weekly Great Videos'
            video_duration: '', // in milliseconds, ex: 120000

            video_stream_type: streamType, // 'live' or 'on-demand'
            video_cdn: 'MUX', // ex: 'Fastly', 'Akamai'
          },
        },
      },
      ...props,
    };
  }

  componentDidMount() {
    // instantiate Video.js
    const { clippedStart, clippedStop } = this.props;
    this.player = videojs(
      this.videoNode,
      this.playerSettings,
      function onPlayerReady() {
        // console.log('onPlayerReady', this.options_.autoplay, this);
        // eslint-disable-next-line no-underscore-dangle
        // if (this.options_.autoplay) {
        //   const promise = this.play();
        //   if (promise !== undefined) {
        //     promise
        //       // eslint-disable-next-line no-unused-vars
        //       .then(_ => {
        //         // console.log('Autoplay started!')
        //         //  NOOP
        //       })
        //       .catch(_ => {
        //         // console.log('Autoplay was prevented. Show a "Play" button so that user can start playback.')
        //       });
        //   }
        // }
      },
    );
    this.player.hlsQualitySelector({
      // displayCurrentQuality: true,
    });
    if (clippedStart && clippedStop) {
      this.player.offset({ start: clippedStart, end: clippedStop });
    }
    this.player
      .play()
      .then(function() {
        // autoplay was successful!
      })
      .catch(error => {
        // Render Play button
      });
  }

  componentDidUpdate(prevProps) {
    const { clippedStart, clippedStop } = this.props;
    if (
      prevProps.clippedStart !== clippedStart ||
      prevProps.clippedStop !== clippedStop
    ) {
      this.player.offset({ start: clippedStart, end: clippedStop });
    }
  }

  // destroy player on unmount
  componentWillUnmount() {
    if (this.player) {
      // TODO: Somehow this will crash the player
      // this.player.dispose();
    }
  }

  render() {
    const { theme } = this.props;
    return (
      <div data-vjs-player>
        {/* eslint-disable-next-line  jsx-a11y/media-has-caption */}
        <video
          ref={node => (this.videoNode = node)}
          className={`video-js vjs-16-9 vjs-big-play-centered ${theme}`}
          data={{ setup: { language: 'nl' } }}
        />
      </div>
    );
  }
}

VideoPlayer.defaultProps = {
  theme: 'vjs-theme-memoriamtv',
};

export default VideoPlayer;
