// https://github.com/AvaisP/react-dropbox-saver/blob/master/react-dropbox-saver.js
import { Component } from 'react';
import loadScript from 'load-script';

const GDRIVE_SDK_URL = 'https://apis.google.com/js/platform.js';

let scriptLoadingStarted = false;

// read more
// https://www.dropbox.com/developers/saver
export default class GoogleDriveSaver extends Component {
  componentDidMount() {
    if (!scriptLoadingStarted) {
      scriptLoadingStarted = true;
      loadScript(GDRIVE_SDK_URL);
    }
  }

  render() {
    const { children } = this.props;
    return children;
  }
}
