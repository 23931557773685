import 'whatwg-fetch'; // To support this in Rails

const decoratedFetch = (path, options) => {
  const domain  = process.env.REACT_APP_MEMORIAM_TV_API_HOST || 'https://api.memoriam.tv';
  const apiPath = process.env.REACT_APP_MEMORIAM_TV_API_PATH || '/v1';
  const newPath = path.replace(apiPath, '');
  return fetch(domain + apiPath + newPath, options).then(response => {
    if (!response.ok && response.status !== 422) {
      throw Error(`${response.status} ${response.statusText}`);
    }
    return response;
  });
};

export default decoratedFetch;
