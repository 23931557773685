/* eslint-disable camelcase */
/* eslint-disable react/prop-types */
import React from 'react';
import prettyBytes from 'pretty-bytes';
import moment from 'moment';
import DropboxSaver from './dropbox-saver';
import GoogleDriveSaver from './google-drive-saver';

export default class Downloads extends React.Component {
  // eslint-disable-next-line class-methods-use-this
  getReadableFileSizeString(fileSizeInBytesParam) {
    let fileSizeInBytes = fileSizeInBytesParam;
    let i = -1;
    const byteUnits = [
      ' kbps',
      ' Mbps',
      ' Gbps',
      ' Tbps',
      'Pbps',
      'Ebps',
      'Zbps',
      'Ybps',
    ];
    do {
      fileSizeInBytes /= 1024;
      i += 1;
    } while (fileSizeInBytes > 1024);
    return Math.max(fileSizeInBytes, 0.1).toFixed(1) + byteUnits[i];
  }

  render() {
    const { downloads, expires_at } = this.props;
    return (
      <>
        <h3>Downloads</h3>
        {downloads.status === 'preparing' && (
          <p>De downloads worden op dit moment voorbereid.</p>
        )}
        {downloads.status === 'ready' && (
          <p>
            <em>
              Downloads zijn beschikbaar tot <br />
              <time dateTime={expires_at}>
                {moment(expires_at).format('LL')}{' '}
                {moment(expires_at).format('LT')}
              </time>
            </em>
          </p>
        )}
        <table className="downloads-list table table-sm text-left">
          {downloads.status === 'ready' &&
            downloads.files.reverse().map(item => (
              <GoogleDriveSaver key={item.name}>
                <DropboxSaver appKey={process.env.REACT_APP_DROPBOX_APP_KEY}>
                  <tr>
                    <td className="description">
                      {prettyBytes(item.filesize)}
                      {/* , {item.width}x{item.height} @{' '}
                      {this.getReadableFileSizeString(item.bitrate)} */}
                    </td>
                    <td>
                      <a href={item.download_url}>
                        <i className="fas fa-download" />
                        &nbsp;
                        {item.name}
                      </a>
                    </td>
                    <td>
                      {/* eslint-disable-next-line jsx-a11y/anchor-has-content */}
                      <a
                        href={item.download_url}
                        className="dropbox-saver"
                        alt="Download with Dropbox"
                      />
                    </td>
                  </tr>
                  {/* <tr>
                    <td>
                      <div
                        className="g-savetodrive"
                        data-src={item.download_url}
                        data-filename={item.name}
                        data-sitename="Memoriam TV"
                      />
                    </td>
                  </tr> */}
                </DropboxSaver>
              </GoogleDriveSaver>
            ))}
        </table>
      </>
    );
  }
}
