import React from 'react';
import PropTypes from 'prop-types';
import { Col } from 'reactstrap';
import moment from 'moment';
import 'moment/locale/nl';

function EventHeader({ event }) {
  return (
    <Col sm={12}>
      <header>
        <h2>{event.name}</h2>
        {event.state && (
          <p className="text-center">
            {event.start_at && (
              <>
                {event.state === 'inactive' && <span>De uitzending begint </span>}
                {moment(event.start_at).format('LL')}
                {event.state === 'inactive' ? ' om ' : ', '}
                <time dateTime={event.start_at}>
                  {moment(event.start_at).format('LT')}
                </time>{' '}
                uur
              </>
            )}
          </p>
        )}
      </header>
    </Col>
  );
}

EventHeader.propTypes = {
  event: PropTypes.shape({
    id: PropTypes.string,
    name: PropTypes.string,
    state: PropTypes.string,
    start_at: PropTypes.string,
  }),
};

EventHeader.defaultProps = {
  event: {
    id: '',
    name: '--',
    state: 'inactive',
    start_at: null,
  },
};
export default EventHeader;
