/* eslint-disable react/prop-types */
import React from 'react';

export default function ErrorMessage(props) {
  const { eventId } = props;
  let eventURL;
  if (eventId) {
    eventURL = `${window.location.protocol}//${
      window.location.host
    }/${eventId.match(/.{1,4}/g).join('-')}`;
  }
  return (
    <article>
      <div className="row justify-content-center">
        <div className="col-10 col-md-6">
          {eventId && (
            <p className="mt-5 text-center">
              <code>{eventURL}</code>
            </p>
          )}
          <p>Dit kan verschillende oorzaken hebben:</p>
          <dl>
            <dt>U heeft de link niet correct overgetypt.</dt>
            <dd>
              Vraag iemand om te controleren of de link correct is overgetypt en
              probeer het nog een keer.
            </dd>
            <dt>De link die u heeft gekregen werkt niet (meer).</dt>
            <dd>
              Dat kan gebeuren. Vraag de uitvaartverzorger of betrokkene om een
              correcte link op te sturen.
            </dd>
            <dt>U heeft een code gekregen van 6 karakters</dt>
            <dd>
              Dan zit u op de verkeerde pagina. U dient naar de pagina
              van de uitvaartverzorger te gaan.
            </dd>
            <dt>Als dat niet helpt:</dt>
            <dd>
              <ul>
                <li>Vraag het eens aan iemand anders.</li>
                <li>
                  Heeft de persoon waarvan u de link heeft gekregen misschien
                  een betere versie?
                </li>
              </ul>
            </dd>
            <dd />
            <dt>Als laatste redmiddel:</dt>
            <dd>
              Vul <a href="https://www.memoriam.tv/contact/">dit formulier</a>{' '}
              in. Helaas kunnen we geen snelle reactie garanderen.
            </dd>
          </dl>
        </div>
      </div>
    </article>
  );
}
