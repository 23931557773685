import React from 'react';
import '../css/spinner.css';

// eslint-disable-next-line react/prop-types
export default function Spinner({ className }) {
  return (
    <div className={`spinner ${className}`}>
      <div className="double-bounce1" />
      <div className="double-bounce2" />
    </div>
  );
}
